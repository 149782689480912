import {SignupSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseAssetWithSize} from './asset.serde'
import {SignupSection} from '../types/signup-section.types'
import {isNotNullish} from '../../utils/nullish'

export function parseSignupSection(s: SignupSectionFieldsFragment): SignupSection {
  const section = validateDatoCmsTypes(s)

  if (!section.topic.topic) throw new RangeError(`Topic expected for sign-up section ${s.title}`)

  return {
    __type: 'SignupSection',
    title: section.title,
    description: section.description,
    form: {
      title: section.formTitle,
      buttonLabel: section.formButtonLabel,
    },
    image: parseAssetWithSize(section.image),
    topic: {
      topic: section.topic.topic,
      sendGridListIds: section.topic.sendgridListIds.map(id => id.value).filter(isNotNullish),
    },
  }
}
